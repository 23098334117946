<template>
  <header class="absolute w-full z-30">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="flex items-center justify-between h-20">

        <!-- Site branding -->
        <div class="flex-shrink-0 mr-4">
          <!-- Logo -->
          <router-link to="/" class="block" aria-label="Cruip">
            <svg height="32px" viewBox="0 0 419 155" xmlns="http://www.w3.org/2000/svg"><g fill="#FFF" fill-rule="evenodd"><path d="M82.855 76.258h4.734c1.313 0 2.328-.43 3.047-1.29.719-.859 1.078-2.109 1.078-3.75 0-1.702-.367-3.058-1.101-4.066-.735-1.007-1.72-1.52-2.954-1.535h-4.804v10.64zM122.583 75.79h3.914c1.375 0 2.406-.458 3.094-1.372.687-.914 1.03-2.137 1.03-3.668 0-3.422-1.335-5.133-4.007-5.133h-4.031V75.79zM197.492 65.617v22.664h2.063c2.297 0 3.914-.605 4.851-1.816.938-1.211 1.43-3.3 1.477-6.27v-5.93c0-3.187-.445-5.41-1.336-6.667-.89-1.258-2.406-1.918-4.547-1.98h-2.508zM238.017 81.273h6.375l-3.187-12.164z"></path><path d="M243.51 154.613c-3.578-15.992-17.681-27.93-34.534-27.93-16.852 0-30.955 11.938-34.534 27.93H6a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6h406.95a6 6 0 0 1 6 6v142.613a6 6 0 0 1-6 6H243.51zM82.855 82h4.593c3.516 0 6.266-.969 8.25-2.906 1.985-1.938 2.977-4.578 2.977-7.922 0-3.344-1.004-6.063-3.012-8.156-2.008-2.094-4.699-3.141-8.074-3.141H75.964V94h6.89V82zm43.15-.469L131.138 94h7.406v-.328l-5.977-13.969c3.297-1.812 4.946-4.984 4.946-9.516 0-3.296-.942-5.84-2.825-7.628-1.883-1.79-4.55-2.684-8.004-2.684h-10.992V94h6.89V81.531h3.423zm46.267-2.297V73.68h-10.734v-8.063h12.656v-5.742h-19.547V94h19.594v-5.719h-12.704v-9.047h10.735zM190.602 94h8.835c4.157 0 7.418-1.277 9.786-3.832 2.367-2.555 3.55-6.074 3.55-10.559v-5.53c-.046-4.407-1.253-7.876-3.62-10.407-2.368-2.531-5.544-3.797-9.528-3.797h-9.023V94zm55.314-6.984L247.744 94h7.36l-10.735-34.125h-6.305L227.4 94h7.29l1.827-6.984h9.399zm46.032-5.696l-4.007-21.445h-5.79l-4.007 21.422-3.211-21.422h-6.867L274.159 94h6.938l3.937-20.11L289.02 94h6.914l6.07-34.125h-6.82l-3.235 21.445zM341.99 94V59.875h-6.867v22.406L325.02 59.875h-6.89V94h6.89V71.617L335.098 94h6.891z"></path></g></svg>
          </router-link>
        </div>

        <!-- Desktop navigation -->
        <nav class="hidden md:flex md:flex-grow">

          <!-- Desktop sign in links -->
          <ul class="flex flex-grow justify-end flex-wrap items-center">
            <li>
              <a href="http://eepurl.com/dB-5-r" class="font-medium text-purple-600 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out">Get updates</a>
            </li>
          </ul>

        </nav>

        <!-- Mobile menu -->
        <div class="md:hidden">

          <!-- Hamburger button -->
          <button class="hamburger" ref="hamburger" :class="{ active: mobileNavOpen }" aria-controls="mobile-nav" :aria-expanded="mobileNavOpen" @click="mobileNavOpen = !mobileNavOpen">
            <span class="sr-only">Menu</span>
            <svg class="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <rect y="4" width="24" height="2" rx="1" />
              <rect y="11" width="24" height="2" rx="1" />
              <rect y="18" width="24" height="2" rx="1" />
            </svg>
          </button>

          <!-- Mobile navigation -->
          <nav id="mobile-nav" ref="mobileNav" class="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out" :style="[ mobileNavOpen ? { maxHeight: $refs.mobileNav.scrollHeight + 'px', opacity: 1 } : { maxHeight: 0, opacity: .8 } ]">
            <ul class="bg-gray-800 px-4 py-2">
              <li>
                <a href="http://eepurl.com/dB-5-r" class="flex font-medium w-full text-purple-600 hover:text-gray-200 py-2 justify-center">Get updates</a>
              </li>
            </ul>
          </nav>

        </div>

      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: 'Header',
  data: function () {
    return {
      mobileNavOpen: false
    }
  },
  methods: {
    clickOutside(e) {
      if (!this.mobileNavOpen || this.$refs.mobileNav.contains(e.target) || this.$refs.hamburger.contains(e.target)) return
      this.mobileNavOpen = false
    },
    keyPress() {
      if (!this.mobileNavOpen || event.keyCode !== 27) return
      this.mobileNavOpen = false
    }    
  },  
  mounted() {
    document.addEventListener('click', this.clickOutside)    
    document.addEventListener('keydown', this.keyPress)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside)
    document.removeEventListener('keydown', this.keyPress)
  }
};
</script>