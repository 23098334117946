<template>
  <section>
    <div class="max-w-3xl mx-auto px-4 sm:px-6">
      <div class="pb-12 md:pb-20">

        <!-- Section header -->
        <h3 class="h4 mb-8" data-aos="fade-up" data-aos-anchor="[data-aos-id-career]" data-aos-delay="400">Problems & Products</h3>

        <!-- Job list -->
        <div class="-my-3" data-aos-id-career>

          <!-- 1st Item -->
          <div class="py-3" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-career]">
            <div class="pb-6 border-b border-gray-800">
              <div class="mb-2">
                <a class="flex justify-between items-center text-xl font-bold text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="http://resumezero.com">
                  <span>Resume Zero</span>
                  <svg class="stroke-current flex-shrink-0 ml-2" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                    <g stroke-width="2" fill="none">
                      <path d="M1 12h20" />
                      <path stroke-linecap="square" d="M14 5l7 7-7 7" />
                    </g>
                  </svg>
                </a>
              </div>
              <div class="text-lg text-gray-400 mb-3">
                Applying to jobs online can be a long, lonely, demoralizing slog. 
                At Resume Zero, we provide a structured program and trusted advice to help you get traction in your work search.
              </div>
              <div class="text-gray-400 flex flex-wrap items-center">
                <div class="inline-flex items-center">
                  <svg class="mr-2 flex-shrink-0" vidth="14" height="17" viewBox="0 0 14 17" xmlns="http://www.w3.org/2000/svg">
                    <path class="fill-current text-gray-300" d="M7 0C3.1 0 0 3.1 0 7c0 1.9.7 3.7 2.1 5 .1.1 4.1 3.7 4.2 3.8.4.3 1 .3 1.3 0 .1-.1 4.2-3.7 4.2-3.8 1.4-1.3 2.1-3.1 2.1-5 .1-3.9-3-7-6.9-7zm0 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
                  </svg>
                  <span>Anywhere</span>
                </div>
                <span class="text-gray-700 mx-3"> - </span>
                <div class="inline-flex items-center">
                  <svg class="mr-2 flex-shrink-0" vidth="14" height="16" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg">
                    <path class="fill-current text-gray-300" d="M9.5 3L11 0H3l1.5 3zM10 5H4c-2 1.458-4 4.235-4 6.4C0 12.573.5 16 7 16c6.495 0 7-3.431 7-4.6 0-2.165-2-4.942-4-6.4z" />
                  </svg>
                  <span>Free</span>
                </div>
              </div>
            </div>
          </div>

          <!-- 2nd Item -->
          <div class="py-3" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-projects]">
            <div class="pb-6 border-b border-gray-800">
              <div class="mb-2">
                <a class="flex justify-between items-center text-xl font-bold text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="https://smallprayer.com/">
                  <span>Small Prayer</span>
                  <svg class="stroke-current flex-shrink-0 ml-2" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                    <g stroke-width="2" fill="none">
                      <path d="M1 12h20" />
                      <path stroke-linecap="square" d="M14 5l7 7-7 7" />
                    </g>
                  </svg>
                </a>
              </div>
              <div class="text-lg text-gray-400 mb-3">
                Feeling like you don't have the support of a small, close group of friends can be lonely and demoralizing.
                Small Prayer is a mobile app that makes it easier for your friends to encourage you about the single hardest thing in your life.
              </div>
              <div class="text-gray-400 flex flex-wrap items-center">
                <div class="inline-flex items-center">
                  <svg class="mr-2 flex-shrink-0" vidth="14" height="17" viewBox="0 0 14 17" xmlns="http://www.w3.org/2000/svg">
                    <path class="fill-current text-gray-300" d="M7 0C3.1 0 0 3.1 0 7c0 1.9.7 3.7 2.1 5 .1.1 4.1 3.7 4.2 3.8.4.3 1 .3 1.3 0 .1-.1 4.2-3.7 4.2-3.8 1.4-1.3 2.1-3.1 2.1-5 .1-3.9-3-7-6.9-7zm0 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
                  </svg>
                  <span>Anywhere</span>
                </div>
                <span class="text-gray-700 mx-3"> - </span>
                <div class="inline-flex items-center">
                  <svg class="mr-2 flex-shrink-0" vidth="14" height="16" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg">
                    <path class="fill-current text-gray-300" d="M9.5 3L11 0H3l1.5 3zM10 5H4c-2 1.458-4 4.235-4 6.4C0 12.573.5 16 7 16c6.495 0 7-3.431 7-4.6 0-2.165-2-4.942-4-6.4z" />
                  </svg>
                  <span>Free</span>
                </div>
              </div>
            </div>
          </div>

          <!-- 3rd Item -->
          <div class="py-3" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-career]">
            <div class="pb-6 border-b border-gray-800">
              <div class="mb-2">
                <a class="flex justify-between items-center text-xl font-bold text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="https://claritykit.io/">
                  <span>Clarity Kit</span>
                  <svg class="stroke-current flex-shrink-0 ml-2" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                    <g stroke-width="2" fill="none">
                      <path d="M1 12h20" />
                      <path stroke-linecap="square" d="M14 5l7 7-7 7" />
                    </g>
                  </svg>
                </a>
              </div>
              <div class="text-lg text-gray-400 mb-3">
                Making big decisions can be tough. It's hard to know when to trust your gut. 
                With Clarity Kit, we turn your subjective gut into objective numbers so you can get a clear read on what you should do.
              </div>
              <div class="text-gray-400 flex flex-wrap items-center">
                <div class="inline-flex items-center">
                  <svg class="mr-2 flex-shrink-0" vidth="14" height="17" viewBox="0 0 14 17" xmlns="http://www.w3.org/2000/svg">
                    <path class="fill-current text-gray-300" d="M7 0C3.1 0 0 3.1 0 7c0 1.9.7 3.7 2.1 5 .1.1 4.1 3.7 4.2 3.8.4.3 1 .3 1.3 0 .1-.1 4.2-3.7 4.2-3.8 1.4-1.3 2.1-3.1 2.1-5 .1-3.9-3-7-6.9-7zm0 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
                  </svg>
                  <span>Anywhere</span>
                </div>
                <span class="text-gray-700 mx-3"> - </span>
                <div class="inline-flex items-center">
                  <svg class="mr-2 flex-shrink-0" vidth="14" height="16" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg">
                    <path class="fill-current text-gray-300" d="M9.5 3L11 0H3l1.5 3zM10 5H4c-2 1.458-4 4.235-4 6.4C0 12.573.5 16 7 16c6.495 0 7-3.431 7-4.6 0-2.165-2-4.942-4-6.4z" />
                  </svg>
                  <span>Paid</span>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Career'
}
</script>